<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + 'delivery-info'" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h1>Страница доставки и оплаты</h1>

      <el-form-item label="SEO-тексты">
        <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
      </el-form-item>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqs" @getSeo="getSeoFaqs" />
      </el-form-item>

      <div :class="$style.content">
        <div :class="$style.sections">
          <h2>Секции</h2>
          <Container
            v-if="form.sections.length"
            tag="div"
            :class="$style.body"
            lock-axis="y"
            :non-drag-area-selector="'.drag-disabled'"
            @drop="onDropSection"
          >
            <Draggable
              tag="div"
              v-for="(section, sectionIndex) in form.sections"
              :key="sectionIndex"
              :class="$style.row"
            >
              <div :class="$style.title">
                <Icon name="draggable" :class="$style.icon" />
                <h3>{{ sectionIndex + 1 }}. Секция {{ section.title }}</h3>
                <div :class="$style.buttons">
                  <Icon
                    name="arrow"
                    @click="handleShowContent(sectionIndex)"
                    :class="[
                      $style.icon,
                      $style.arrow,
                      { [$style.active]: section.isShow },
                      'drag-disabled',
                    ]"
                  />
                  <el-tooltip content="Удаление секции" placement="left">
                    <el-button
                      type="danger"
                      plain
                      circle
                      icon="el-icon-delete"
                      :class="[$style.remove, 'drag-disabled']"
                      @click="removeSection(sectionIndex)"
                    />
                  </el-tooltip>
                </div>
              </div>
              <div v-if="section.isShow" :class="$style.content">
                <el-form-item
                  label="Заголовок секции"
                  :prop="'sections.' + sectionIndex + '.title'"
                  :rules="rules.sections.title"
                  class="drag-disabled"
                >
                  <el-input v-model="section.title" />
                </el-form-item>
                <div :class="$style.blocks">
                  <Container
                    v-if="section.block.length"
                    tag="div"
                    :class="$style.body"
                    lock-axis="y"
                    :non-drag-area-selector="'.drag-disabled'"
                    @drop="onDropBlocks($event, sectionIndex)"
                  >
                    <Draggable
                      tag="div"
                      v-for="(block, index) in section.block"
                      :key="index"
                      :class="$style.row"
                    >
                      <div :class="$style.title">
                        <Icon name="draggable" :class="$style.icon" />
                        <h4>{{ index + 1 }}. Блок {{ block.title }}</h4>
                        <div :class="$style.buttons">
                          <Icon
                            name="arrow"
                            @click="handleShowBlock(sectionIndex, index)"
                            :class="[
                              $style.icon,
                              $style.arrow,
                              { [$style.active]: block.isShow },
                              'drag-disabled',
                            ]"
                          />
                          <el-tooltip content="Удаление блока" placement="left">
                            <el-button
                              type="danger"
                              plain
                              circle
                              icon="el-icon-delete"
                              :class="[$style.remove, 'drag-disabled']"
                              @click="removeBlock(sectionIndex, index)"
                            />
                          </el-tooltip>
                        </div>
                      </div>
                      <div v-if="block.isShow" :class="$style.content">
                        <el-form-item
                          label="Заголовок блока"
                          class="drag-disabled"
                        >
                          <el-input v-model="block.title" />
                        </el-form-item>
                        <el-form-item label="С таблицей" class="drag-disabled">
                          <el-checkbox v-model="block.withTable"> </el-checkbox>
                        </el-form-item>
                        <el-form-item
                          label="Иконка"
                          label-width="12rem"
                          class="drag-disabled"
                        >
                          <el-select
                            v-model="block.iconName"
                            filterable
                            placeholder="Выберите иконку"
                            :popper-class="$style.iconOptions"
                          >
                            <el-option
                              v-for="(item, index) in paymentIconsOptions"
                              :key="index"
                              :label="item"
                              :value="item"
                            >
                              <div v-if="item">
                                <img :src="`/img/payments/${item}.webp`" />
                                <p>{{ item }}</p>
                              </div>
                              <p v-if="!item">Без иконки</p>
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <TextEditor :value.sync="block.text" />
                      </div>
                    </Draggable>
                  </Container>
                  <el-button
                    type="primary"
                    size="small"
                    :class="$style.button"
                    @click="addBlock(sectionIndex)"
                    >Добавить блок</el-button
                  >
                </div>
              </div>
            </Draggable>
          </Container>
          <el-button
            type="primary"
            size="small"
            @click="addSection"
            :class="$style.button"
            >Добавить секцию</el-button
          >
        </div>
      </div>
      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import Icon from '@/components/atoms/Icon.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

export default {
  components: {
    SeoBlock,
    TextEditor,
    Icon,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  async created() {
    await this.getContent()
  },
  data() {
    return {
      paymentIconsOptions: [
        '',
        'account',
        'alfabank',
        'cash',
        'credit-card',
        'sberbank',
      ],
      form: {
        sections: [],
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        sections: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'delivery-info',
          'addwine',
        )

      value.content.sections = value.content?.sections?.map((section) => {
        const blocks = section.block?.map((block) => {
          return {
            ...block,
            isShow: false,
          }
        })
        return { ...section, block: blocks, isShow: false }
      })

      loading.close()

      if (error) return
      this.form = value.content
      this.form.seoTexts =
        this.form?.seoTexts?.map((seoText) => seoText.id) ?? []
      this.form.seoFaqs = this.form?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'delivery-info',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }

          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })

          this.$router.push('/addwine/content/pages')
        }
      })
    },
    addSection() {
      this.form.sections.push({
        title: '',
        block: [],
        isShow: false,
      })
    },
    removeSection(sectionIndex) {
      this.form.sections.splice(sectionIndex, 1)
    },
    addBlock(sectionIndex) {
      this.form.sections[sectionIndex].block.push({
        title: '',
        text: '',
        withTable: false,
        iconName: '',
        isShow: false,
      })
    },
    removeBlock(sectionIndex, blockIndex) {
      this.form.sections[sectionIndex].block.splice(blockIndex, 1)
    },
    handleShowContent(sectionIndex) {
      this.form.sections[sectionIndex].isShow =
        !this.form.sections[sectionIndex].isShow
    },
    handleShowBlock(sectionIndex, blockIndex) {
      this.form.sections[sectionIndex].block[blockIndex].isShow =
        !this.form.sections[sectionIndex].block[blockIndex].isShow
    },
    removeClassesAfterDraggle() {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
    },
    onDropSection(dropResult) {
      this.removeClassesAfterDraggle()

      this.form.sections = this.applyDrag(this.form.sections, dropResult)
    },
    onDropBlocks(dropResult, sectionIndex) {
      this.removeClassesAfterDraggle()

      this.form.sections[sectionIndex].block = this.applyDrag(
        this.form.sections[sectionIndex].block,
        dropResult,
      )
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
  },
}
</script>

<style lang="scss" module>
.iconOptions > div > div > ul {
  li {
    border-bottom: 0.063rem solid $extra-light-gray;
    padding: 1rem;
    height: auto;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    img {
      width: 5rem;
    }
  }
}
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .form {
    h1,
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .seoTexts {
      width: 100%;
    }
    .content {
      .sections {
        padding: 1rem;
        border: 0.3rem solid $extra-light-gray;
        .body {
          margin-bottom: 1.5rem;
          .row {
            .title {
              h3 {
                margin: 0 1rem;
              }
              justify-content: space-between;
              display: flex;
              align-items: center;
              max-width: 100%;
              .buttons {
                display: flex;
                align-items: center;
                margin-left: auto;
              }
              .icon {
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
              }
              .arrow {
                margin-right: 1rem;
                transition: transform 0.3s ease;
                transform: rotate(-180deg);
                &.active {
                  transition: transform 0.3s ease;
                  transform: rotate(-90deg);
                }
              }
            }
            &:not(:last-child) {
              border-bottom: 0.063rem solid $dark-blue;
            }
            padding: 1rem 0;
            .content {
              margin: 1.5rem;
              & > div:last-child {
                margin-bottom: 0;
              }
              .blocks {
                .button {
                  margin-top: 1.5rem;
                }
                .body {
                  .row {
                    &:not(:last-child) {
                      border-bottom: 0.063rem solid $extra-light-gray;
                    }
                    .title {
                      h4 {
                        margin: 0 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .seo {
      margin-top: 1.5rem;
    }
  }
}
</style>
